.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(37, 37, 37, 0.49);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 22;
  overflow: hidden;
}
.container ul {
  list-style-type: disc;
}
.container li {
  color: #546e7a;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0.08px;
}
.container li::marker {
  color: #de5e62;
}
.contentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 1076px;
  height: 90vh;
  padding: 34px 34px 0px 52px;
}

.top {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top h1 {
  color: #263238;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.redline {
  width: 216px;
  height: 4px;
  background-color: #de5e62;
}
.bottom {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  width: 100%;
  height: 90%;
  overflow: scroll;
}
.bottom p {
  color: #546e7a;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.08px;
}
.grid {
  width: 50%;
  padding-right: 34px;
  padding-top: 20px;
  height: 150px;
  border-bottom: 1px solid #cfd8dc;
  padding-left: 40px;
}
.gridRight {
  padding-left: 45px;
}
.cancel {
  position: absolute;
  right: 20px;
  top: 20px;
  color: black;
  font-weight: 700;
}
@media only screen and (max-width: 768px) {
  .bottom {
    width: 800px;
  }
  .top h1 {
    font-size: 35px;
  }
  .bottom p {
    font-size: 13px;
  }
  .container ul {
    list-style-type: disc;
  }
  .contentWrapper {
    padding: 20px 20px 0px 25px;
  }
  .container li {
    font-size: 13px;
  }
  .grid {
    padding-right: 5px;
    padding-left: 5px;
  }
  .cancel img {
    width: 30px;
  }
  .contentWrapper {
    width: 95vw;
    height: 90vh;
  }
}
