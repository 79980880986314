.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
}
.container2 {
  display: flex;
  justify-content: space-between;
  width: 1280px;
  width: 1280px;
  height: 90%;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 55%;
}
.left p {
  line-height: 30px;
  word-spacing: 10px;
}
.right {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 40%;
}
.rightImg {
  position: relative;
  width: 490px;
  height: 520px;
}

.backgroundImage {
  margin-top: 500px;
  margin-left: -150px;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  scale: 1.6;
  object-fit: contain;
  min-width: 1000px;
}
.redFilter {
  position: absolute;
  width: 252px;
  height: 284px;
  background-color: #de5e62;
  mix-blend-mode: multiply;
  right: -40px;
  bottom: -45px;
}
.leftTop h1 {
  color: black;
  font-weight: 700;
  font-size: 50px;
}
.redLine {
  width: 216px;
  height: 4px;
  background-color: #de5e62;
}
@media only screen and (max-width: 768px) {
  .container {
    height: 1000px;
  }
  .container2 {
    flex-direction: column;
    height: 90%;
  }
  .right {
    width: 100vw;
  }
  .rightImg {
    width: 300px;
    height: 350px;
  }
  .left {
    width: 100vw;
    padding: 20px;
  }
  .leftTop h1 {
    color: black;
    font-weight: 700;
    font-size: 30px;
  }
  .left p {
    line-height: 22px;
    word-spacing: 10px;
    font-size: 14px;
  }
  .redFilter {
    width: 100px;
    height: 100px;
    right: -40px;
    bottom: -40px;
  }
  .backgroundImage {
    scale: 2;
  }
}
