.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  min-height: 1100px;
  overflow: hidden;
}
.container h1 {
  color: black;
  font-weight: 700;
  font-size: 50px;
  width: fit-content;
}
.backgroundImage {
  margin-top: 600px;
  margin-left: -150px;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  min-width: 1280px;
  scale: 1.6;
  object-fit: contain;
}
.redLine {
  width: 216px !important;
  height: 4px !important;
  background-color: #de5e62 !important;
  flex: none;
}
.boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  height: 780px;
}

.box {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 8px 18px 0px #00000014;
  border: 1px solid #eceff1;
  gap: 10px;
  padding: 38px;
  width: 380px;
  height: 400px;
  aspect-ratio: 1/1;
}
.box h3 {
  font-weight: 600;
  font-size: 20px;
}
.box p {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #546e7a;
}
.imgWrapper {
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
}
.imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0px 20px;
    height: fit-content;
    padding: 20px;
  }
  .container h1 {
    font-size: 25px;
  }
  .boxes {
    height: fit-content;
  }
}
