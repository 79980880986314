.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 1100px;
  width: 100%;
}
.backgroundTop {
  background-color: #df292fcf;
  width: 100%;
  height: 50%;
}
.backgroundBottom {
  background-color: white;
  width: 100%;
  height: 50%;
}
.img {
  position: absolute;
  width: 100%;
  z-index: -1;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 75px 0px 83px 0px;
  width: 100%;
  height: 100%;
}
.contentContainer h1 {
  color: white;
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
}
.buttons {
  display: flex;
  gap: 62px;
}
.buttons button {
  color: white;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 4px solid transparent;
}
.buttons button:hover {
  border-bottom: 4px solid white;
}
.cards {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.card {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 730px;
  box-shadow: 0px 8px 18px 0px rgba(0, 0, 0, 0.08);
}
.imgWrapper {
  width: 100%;
  height: 400px;
}
.imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardBottom {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  height: calc(730px - 400px);
  padding: 55px;
}
.cardBottom p {
  color: var(--black-blue-70, #546e7a);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.08px;
}
.cardBottom p strong {
  font-size: 40px;
  width: 0px;
  height: 0px;
}
.cardBottom h3 {
  color: var(--black-blue-100, #263238);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.12px;
}
.cardBottom button {
  display: flex;
  gap: 44px;
  background: #de5e62;
  padding: 13px 16px;
  width: fit-content;
  color: white;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.08px;
}
.a {
  position: absolute;
  top: 0px;
  width: 1280px;
  height: 100%;
  margin-left: 50%;
  transform: translate(-50%);
}
.inkotermsContainer {
  width: 100%;
  height: 760px;
}
.inkotermsContainer img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
@media only screen and (max-width: 768px) {
  .container {
    height: 1500px;
  }
  .a {
    width: 90vw;
  }
  .contentContainer h1 {
    font-size: 30px;
  }
  .img {
    height: 50%;
  }
  .buttons button {
    font-size: 15px;
  }
  .inkotermsContainer {
    height: fit-content;
  }
  .cards {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .card {
    width: 85vw;
    height: 600px;
  }
  .cardBottom {
    height: 50%;
    padding: 30px;
  }
  .cardBottom button {
    display: flex;
    gap: 10px;
    background: #de5e62;
    padding: 5px 8px;
    width: fit-content;
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.08px;
  }
  .imgWrapper {
    width: 100%;
    height: 50%;
  }
  .imgWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
