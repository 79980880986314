.images{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover ;
    opacity: 0.7;
}

@media (max-width: 768px) {
    .images {
      height: 20vh
    }
  }