.container {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  z-index: 1;
  transition: all 0.3s;
  border-bottom:1px solid rgba(255, 255, 255, 0.20);
}

.secondContainer {
  width: 1280px;
  min-width: 1200px;
  height: 100%;
  display: flex;
}
.logoWrapper {
  display: flex;
  align-items: center;
  width: 20%;
  background-color: transparent;
}
.logo {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.navbarRight {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  width: 100%;
  height: 100%;
}
.top div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.top button {
  border: none;
  padding: 5px 10px;
  font-weight: bold;
  color: black;
  background-color: white;
}
.bottom {
  width: 100%;
  height: 100%;
}
.menu {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  height: 100%;
}
.menuItemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.menuItemBorder {
  width: 0%;
  height: 3px;
  background-color: white;
  transition: width 1s;
}

.menuItemWrapper:hover .menuItemBorder {
  width: 100%;
}
.mobileMenu{
  display: none;
}
.language{
  position: relative;
}
.languageDropdown{
  display: flex;
  flex-direction: column;
  right: -25px;
  bottom: -80px;
  position: absolute;
  width: 50px;
  height: 80px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}
.languageDropdown img {
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .navbarRight{
    display: none;
  }
  .menuItem{
    display: none;
  }
  .secondContainer{
    width: 100vw ;
    min-width: 0px;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .mobileMenu{
    display: flex;
    align-items: center;
    height: 100%;
  }
}