.container{
    width: 100vw;
    height: 90vh;
}

.images{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover ;
}

@media (max-width: 768px) {
    .images {
      height: 100vh
    }
  }

