.right {
  position: relative;
  justify-content: center;
  height: 100%;
  width: 40%;
}
.rightImg {
  position: relative;
  width: 486px;
  height: 520px;
}
.rectangle {
  position: absolute;
  width: 252px;
  height: 284px;
  background-color: #DE5E62;
  mix-blend-mode: multiply;
  right: -40px;
  bottom: -40px;
}

.images{
    min-width: 100%;
    min-height: 100%;
    object-fit: cover ;
    opacity: 0.6;
}

@media (max-width: 1600px) {
    .images {
        width: 100%;
      height: 35vh
    }
  }