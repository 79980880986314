.container{
  display: flex;
  justify-content: flex-end;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.239);
  z-index: 999;
}
.menu{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100vh;
  min-height: 400px;
  background-color: white;
  padding: 0px 20px;

}
.menuItems{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.menuItem{
  display: flex;
  gap: 10px;
  border-bottom: 0.1px solid rgba(0, 0, 0, 0.193);
  font-size: 12px;
  padding-bottom: 10px;
}
.menuItem img{
  width: 30px;
  border-radius: 5px;
}
.cancel{
  display: flex;
  align-items: center;
  justify-content: space-between  ;
  width: 100%;
  height: 10%;
}
.cancel img{
  width: 30px;
}